import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const ClientLogo: React.FC<{ width: string; url: string }> = ({
  children,
  width,
  url
}) => {
  const { transitions } = useTheme();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box
        component="a"
        href={url}
        target="_blank"
        rel="noopener"
        sx={{
          width,
          ":hover": { transform: "scale(1.1)", transformOrigin: "center" },
          transition: transitions.create(["transform"], {
            duration: transitions.duration.shortest,
            easing: transitions.easing.sharp
          })
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

ClientLogo.displayName = "ClientLogo";

export default ClientLogo;
