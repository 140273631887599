import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";

type OverviewCardProps = {
  heading: string;
  content?: React.ReactNode;
  bgUrl?: string;
};

const OverviewCard: React.FC<OverviewCardProps> = ({
  heading,
  bgUrl,
  content
}) => {
  const { palette, typography } = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: bgUrl ? `url(${bgUrl})` : undefined,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      <Box
        sx={{
          p: 6,
          backgroundColor: alpha(palette.background.paper, 0.3)
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            px: 0.5,
            display: "inline",
            fontSize: "2em",
            fontWeight: typography.fontWeightBold,
            backgroundColor: alpha(palette.background.paper, 0.8)
          }}
        >
          {heading}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            px: 0.5,
            backgroundColor: alpha(palette.background.paper, 0.8),
            mt: 3
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

OverviewCard.displayName = "OverviewCard";

export default OverviewCard;
