import React from "react";

import ClientLogo from "@ui/core/ClientLogo";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const ClientsSection: React.FC = () => {
  return (
    <Container>
      <Typography variant="h3" sx={{ mt: 4, mb: 10, textAlign: "center" }}>
        Projects and Clients
      </Typography>

      <Grid container spacing={5} rowSpacing={12}>
        <ClientLogo width="70%" url="https://re.events/">
          <img src="/clients/reevents-logo.svg" />
        </ClientLogo>

        <ClientLogo width="70%" url="https://www.slidebox.io/">
          <img src="/clients/slidebox-logo.svg" />
        </ClientLogo>

        <ClientLogo width="80%" url="https://www.50hertz.com/">
          <img width="100%" src="/clients/50hertz-logo.png" />
        </ClientLogo>

        <ClientLogo width="100%" url="https://www.durstexpress.de">
          <img src="/clients/durstexpress-logo.svg" />
        </ClientLogo>

        <ClientLogo width="60%" url="https://www.gbc-engineers.de/">
          <img src="/clients/gbc-logo.png" width="100%" />
        </ClientLogo>

        <ClientLogo width="80%" url="https://www.ferret-go.com/de/">
          <img src="/clients/ferret-logo.svg" />
        </ClientLogo>

        <ClientLogo width="80%" url="https://goodtravel.de/">
          <img src="/clients/goodtravel-logo.svg" />
        </ClientLogo>

        <ClientLogo width="60%" url="https://www.territory-influence.com/">
          <img src="/clients/territory-logo.svg" />
        </ClientLogo>
      </Grid>
    </Container>
  );
};

ClientsSection.displayName = "ClientsSection";

export default ClientsSection;
