import React from "react";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Link from "@ui/core/Link";

const RollingLink: React.FC<{ label: string; to: string }> = ({
  label,
  to
}) => {
  const { spacing, palette, typography } = useTheme();

  return (
    <Link
      to={to}
      sx={{
        lineHeight: spacing(5),
        perspective: "1000px",
        color: palette.secondary.main,
        textTransform: "uppercase",
        fontWeight: typography.fontWeightBold,
        ":hover span": {
          transform: `rotateX(90deg) translateY(${spacing(-2.5)})`,
          "::before": {
            background: palette.primary.main
          }
        }
      }}
    >
      <Box
        component="span"
        sx={{
          position: "relative",
          display: "inline-block",
          padding: "0 14px",
          background: palette.common.white,
          transition: "transform 0.3s",
          transformOrigin: "50% 0",
          transformStyle: "preserve-3d",
          "::before": {
            position: "absolute",
            top: "100%",
            left: "0",
            width: "100%",
            height: "100%",
            background: palette.primary.dark,
            content: `'${label}'`,
            textAlign: "center",
            color: palette.primary.contrastText,
            transition: "background 0.3s",
            transform: "rotateX(-90deg)",
            transformOrigin: "50% 0"
          }
        }}
      >
        {label}
      </Box>
    </Link>
  );
};

RollingLink.displayName = "RollingLink";

export default RollingLink;
