import React from "react";

import OverviewCard from "@/ui/core/OverviewCard";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const CompanyOverviewSection: React.FC = () => {
  const ELEVATION = 20;

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: "flex", flexWrap: { xs: "wrap", md: "nowrap" } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexBasis: { xs: "100%", md: "50%" },
            mr: { xs: 0, md: 5 },
          }}
        >
          <Paper
            sx={{ mt: { xs: -5, md: -10 }, mb: { xs: 5, md: 10 } }}
            elevation={ELEVATION}
          >
            <OverviewCard
              bgUrl="/remote-meeting.svg"
              heading="Software Conception"
              content="Successful realization of software requires the right concept and good execution planning."
            />
          </Paper>

          <Paper elevation={ELEVATION} sx={{ mb: 5 }}>
            <OverviewCard
              bgUrl="/developer-activity.svg"
              heading="Frontend Specialist"
              content="To build rich web applications, TypeScript is used to build software that is maintainable and well documented."
            />
          </Paper>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexBasis: { xs: "100%", md: "50%" },
            ml: { xs: 0, md: 5 },
          }}
        >
          <Paper sx={{ mb: { xs: 5, md: 10 } }} elevation={ELEVATION}>
            <OverviewCard
              bgUrl="/design-process.svg"
              heading="UI/UX Design"
              content="Good design is just as important as good code. I believe in fun and intuitive user interfaces driven by data-based improvements."
            />
          </Paper>

          <Paper elevation={ELEVATION}>
            <OverviewCard
              bgUrl="/pull-request.svg"
              heading="Support and Maintenance"
              content="Monitoring and tracking the health of your app and making sure everything stays up to date and secure."
            />
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

CompanyOverviewSection.displayName = "CompanyOverviewSection";

export default CompanyOverviewSection;
