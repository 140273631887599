import React, { useState } from "react";

import Scrollspy from "react-scrollspy";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme, alpha } from "@mui/material/styles";

type VerticalPageNavProps = {
  anchorIds: Array<string>;
};

const VerticalPageNav: React.FC<VerticalPageNavProps> = ({ anchorIds }) => {
  const { zIndex, palette, spacing } = useTheme();
  const [activeAnchorId, setActiveAnchorId] = useState(anchorIds[0]);

  return (
    <Box
      sx={{
        position: "fixed",
        right: spacing(5),
        top: 0,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: zIndex.drawer,
        pointerEvents: "none",
      }}
    >
      <Scrollspy
        items={anchorIds}
        currentClassName="current"
        onUpdate={(el) => setActiveAnchorId(el?.id)}
        style={{ display: "none" }}
        offset={-300}
      />

      <Tabs
        orientation="vertical"
        value={activeAnchorId}
        TabIndicatorProps={{
          style: {
            width: spacing(1),
            zIndex: 0,
            borderRadius: spacing(0.5),
            overflow: "hidden",
          },
        }}
        sx={{
          width: spacing(1),
          bgcolor: alpha(palette.primary.light, 0.2),
          pointerEvents: "all",
          borderRadius: spacing(0.5),
          overflow: "hidden",
        }}
        onChange={(e, newValue) =>
          document
            .getElementById(newValue)
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      >
        {anchorIds.map((anchorId) => (
          <Tab value={anchorId} key={`vertical_page__${anchorId}`} />
        ))}
      </Tabs>
    </Box>
  );
};

VerticalPageNav.displayName = "VerticalPageNav";

export default VerticalPageNav;
