import React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessIcon from "@mui/icons-material/Business";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useTheme } from "@mui/material/styles";

const ContactSection: React.FC = () => {
  const { spacing, palette } = useTheme();
  return (
    <Container>
      <Typography variant="h3" sx={{ mt: 4, mb: 10, textAlign: "center" }}>
        Get In Touch
      </Typography>

      <Grid container spacing={5}>
        <Grid item xs={12}>
          <List
            sx={{
              p: 3,
              m: "auto",
              maxWidth: spacing(60),
              bgcolor: palette.secondary.light,
              borderRadius: spacing(3),
            }}
          >
            <ListItem>
              <ListItemIcon sx={{ color: palette.common.white }}>
                <BusinessIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary="Adam Cooper - Freelance Software developer" />
            </ListItem>

            <ListItem>
              <ListItemIcon sx={{ color: palette.common.white }}>
                <RoomIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary="Berlin, Germany" />
            </ListItem>

            <ListItem button component="a" href="mailto:mail@adamcooper.de">
              <ListItemIcon sx={{ color: palette.common.white }}>
                <EmailIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary="mail@adamcooper.de" />
            </ListItem>

            <ListItem button component="a" href="tel:+4917620415477">
              <ListItemIcon sx={{ color: palette.common.white }}>
                <PhoneIphoneIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary="+49 176 20415477" />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

ContactSection.displayName = "ContactSection";

export default ContactSection;
