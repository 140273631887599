/**
 * iterate over a ts enum with typed key values
 *
 * @example
 * enum FOOBAR {
 *  BAR = "BAR"
 *  FOO = "FOO"
 * }
 *
 * enumKeys(FOOBAR).map(key => key) // key will have type "BAR" | "FOO"
 */
function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}

export default enumKeys;
