import React, { useState, useEffect } from "react";

import TextTransition from "react-text-transition";
import { useInView } from "react-intersection-observer";
import { css, keyframes } from "@emotion/css";

import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  50%  { transform: rotate(180deg) }
  100%   { transform: rotate(360deg) }
`;

const rotatingHeroImage = css`
  animation-name: ${rotate};
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  max-height: 600px;
`;

const HeroBackground: React.FC = ({ children }) => {
  const { transitions, palette, spacing } = useTheme();
  const [textIteration, setTextIteration] = useState<number>(0);
  const [delay, setDelay] = useState<number>(0);
  const { ref, inView } = useInView({
    threshold: 0.3
  });

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 300);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    const speed = 2000;

    setDelay(speed / 1.5);

    setTimeout(() => {
      setTextIteration(index => index + 1);
      intervalId = setInterval(
        () => setTextIteration(index => index + 1),
        speed * 3
      );
    }, speed);

    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1,
          background: `linear-gradient(25deg, ${palette.primary.main}, 30%, ${palette.secondary.main} 90%)`,
          opacity: inView ? 1 : 0,
          transition: transitions.create(["opacity"], {
            duration: transitions.duration.standard,
            easing: transitions.easing.sharp,
          }),
        }}
      ></Box>

      <Box
        ref={ref}
        sx={{
          position: "relative",
          height: "90vh",
          minHeight: { xs: "500px", md: "800px" },
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            zIndex: 0,
            opacity: 0.2,
            position: "absolute",
            display: "flex",
            width: 1,
            height: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { xs: "80%", md: "50%" } }}>
            <img
              className={rotatingHeroImage}
              src="/geometry.svg"
              width="100%"
            />
          </Box>
        </Box>

        <Box
          sx={{
            zIndex: 1,
            position: "absolute",
            display: "flex",
            width: 1,
            height: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Fade in={fadeIn}>
            <Typography
              variant="h1"
              sx={{
                position: "relative",
                textAlign: "center",
                px: 5,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextTransition
                text={["Concept.", "Design."][textIteration % 2]}
                delay={delay * 1}
              />
              <TextTransition
                text={["Model.", "Test."][textIteration % 2]}
                delay={delay * 2}
              />
              <TextTransition
                text={["Implement.", "Iterate."][textIteration % 2]}
                delay={delay * 3}
              />

              <Box
                component="small"
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: "0",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "0.33em",
                  paddingTop: spacing(3),
                }}
              >
                Software systems for the Web
              </Box>
            </Typography>
          </Fade>
        </Box>
        {children}
      </Box>
    </>
  );
};

HeroBackground.displayName = "HeroBackground";

export default HeroBackground;
