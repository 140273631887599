import React from "react";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import { SECTION_IDS } from "@/pages/index";

import RollingLink from "@ui/core/RollingLink";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";

const MainNav: React.FC = () => {
  const { spacing } = useTheme();

  return (
    <Box
      component="nav"
      sx={{
        px: 4,
        pt: 4,
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        justifyContent: { xs: "center", md: "space-between" },
        position: "relative",
        zIndex: 1000,
      }}
    >
      <AnchorLink to="/">
        <Box display="flex" flexWrap="wrap">
          <Avatar
            src="/adam_cooper_profile.jpg"
            sx={{
              height: spacing(6),
              width: spacing(6),
              mr: 2,
              border: "5px solid white",
            }}
          />
          <img
            src="/adam_cooper_logo.svg"
            height={spacing(6)}
            style={{ maxWidth: "calc(100% - 100px)" }}
          />
        </Box>
      </AnchorLink>

      <Hidden mdDown implementation="css">
        <Box sx={{ ml: "auto", bgcolor: "white" }}>
          <RollingLink label="Services" to={`/#${SECTION_IDS.services}`} />
          <RollingLink label="Clients" to={`/#${SECTION_IDS.clients}`} />
          <RollingLink label="Contact" to={`/#${SECTION_IDS.contact}`} />
        </Box>
      </Hidden>
    </Box>
  );
};

MainNav.displayName = "MainNav";

export default MainNav;
