import React from "react";
import { PageProps } from "gatsby";

import enumKeys from "@/utils/enumKeys";
import HeroBackground from "@/components/HeroBackground";
import MainNav from "@/components/MainNav";
import CompanyOverviewSection from "@/sections/CompanyOverviewSection";
import ContactSection from "@/sections/ContactSection";
import ClientsSection from "@/sections/ClientsSection";

import PageSection from "@ui/core/PageSection";
import VerticalPageNav from "@ui/core/VerticalPageNav";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import { useTheme } from "@mui/material/styles";

export enum SECTION_IDS {
  "start" = "start",
  "services" = "services",
  "clients" = "clients",
  "contact" = "contact"
}

const IndexPage: React.FC<PageProps> = () => {
  const { palette } = useTheme();

  return (
    <>
      <Hidden lgDown implementation="css">
        <VerticalPageNav anchorIds={enumKeys(SECTION_IDS)} />
      </Hidden>

      <Box sx={{ scrollBehavior: "smooth" }}>
        <PageSection anchorId={SECTION_IDS.start}>
          <HeroBackground>
            <MainNav />
          </HeroBackground>
        </PageSection>

        <PageSection anchorId={SECTION_IDS.services}>
          <CompanyOverviewSection />
        </PageSection>

        <PageSection anchorId={SECTION_IDS.clients}>
          <Box
            sx={{
              mt: { xs: 6, md: 20 },
              pt: 10,
              pb: 18,
              background: `linear-gradient(25deg, ${palette.secondary.main}, 30%, ${palette.info.main} 90%)`
            }}
          >
            <ClientsSection />
          </Box>
        </PageSection>

        <PageSection anchorId={SECTION_IDS.contact}>
          <Box sx={{ pt: { xs: 8, md: 10 } }}>
            <ContactSection />
          </Box>
        </PageSection>
      </Box>
    </>
  );
};

IndexPage.displayName = "IndexPage";

export default IndexPage;
