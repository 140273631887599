import React from "react";

import Box from "@mui/material/Box";

type PageSectionProps = {
  anchorId: string;
};

const PageSection: React.FC<PageSectionProps> = ({ anchorId, children }) => {
  return <Box id={anchorId}>{children}</Box>;
};

PageSection.displayName = "PageSection";

export default PageSection;
